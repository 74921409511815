<template>
  <!-- 库存总表 -->
  <div class="user">
    <all-log :dialogLog="dialogLog" />
    <!-- 删除用户提示 -->
    <el-dialog :visible.sync="dialogVisible" width="30%" :append-to-body="true">
      <div slot="title" class="dialog-footer">
        <div class="title">删除表</div>
      </div>
      <div style="margin-bottom: 10px">{{ userdelinfo.name }}</div>
      <el-cascader
        placeholder="关联任务"
        :options="optionsTask"
        :props="optionPropsTask"
        v-model="delForm.task_id"
        filterable
      ></el-cascader>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setDel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <div class="menu-box">
        <h2>出入库管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="5">
              <el-input placeholder="请输入内容" v-model="getInfo.info">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="catalogue"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" @click="addOpen()"
                >添加库存总表</el-button
              >
              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
            <!-- <el-col :span="13" id="recordAll">
          <Record
            :tags="tags"
            @close="close"
            @changeRecord="changeRecord"
            :recordLeft="recordLeft"
        /></el-col> -->
          </el-row>
          <!-- 表格 -->
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column
              prop="name"
              label="商品名称"
              width="120px"
            ></el-table-column>
            <el-table-column prop="acc_state" label="状态" width="100px">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.acc_state === 0"
                  >商品</el-tag
                >
                <el-tag type="danger" v-else>配件</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              label="数量"
              width="100px"
            ></el-table-column>
            <el-table-column prop="pd_time" label="过期日期"></el-table-column>
            <el-table-column
              prop="price"
              label="采购价"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="class_name"
              label="分类名称"
              width="120"
            ></el-table-column>
            <el-table-column prop="sl_name" label="仓库位置"></el-table-column>

            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="editOpen(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="delOpen(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="pageChange"
            :pager-count="15"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.getInfo.page_num"
            background
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getDel,
  getSearch,
  getSearchDel,
} from '@/api/psi/storageAll.js'

import { getOption2 } from '@/api/psi/commodity.js'
import Record from '@/components/Record.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Record },
  data() {
    //这里存放数据
    return {
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      delForm: {
        task_id: '',
      },
      optionsTask: [],

      dialogLog: {
        state: false,
      },
      active: 0,
      tags: [],
      recordLeft: '0',
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'sub',
      },
      openState: true,
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
      },
      //导航
      nav: [],
      //上级列表
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {},
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      formLabelWidth: '80px',
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dialogOpen() {
      this.dialogLog.state = true
    },
    addOpen() {
      this.$router.push({
        path: '/addstorage',
        query: {
          name: '增加总表',
        },
      })
    },
    setRecord(index) {
      let width = 120
      let widthAll = document.querySelector(`#recordAll`).offsetWidth
      if (widthAll == 0) {
        widthAll = 770
      }
      console.log(widthAll)
      let itemWidth = index * width + width
      if (itemWidth > widthAll) {
        let recordLeft = itemWidth - widthAll + 50
        this.recordLeft = -recordLeft + 'px'
      } else {
        this.recordLeft = '0'
      }
    },
    async close(item, index) {
      let info = await getSearchDel({ id: item.id })
      this.judge(info)
      this.logList()
    },
    changeRecord(item, index) {
      this.active = item.id
      this.setRecord(index)
      this.getInfo.info = item.name
      this.catalogue()
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },

    //打开编辑
    editOpen(row) {
      this.$router.push({
        path: '/editstorage',
        query: {
          id: row.id,
          name: '编辑总表',
        },
      })
    },
    async changeState(data) {
      let info = await getState({ id: data.id })
      this.judge(info)
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.dialogVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    //删除打开
    delOpen(row, form) {
      this.dialogVisible = true
      this.userdelinfo = row
    },
    async setDel() {
      let info = await getDel({
        id: this.userdelinfo.id,
        task_id: this.delForm.task_id,
      })
      this.judge(info)
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {}
      console.log('close')
    },
    async logList() {
      let info2 = await getSearch()
      this.tags = info2.data.data
    },
    //目录接口
    async catalogue() {
      let info = await getList(this.getInfo)
      this.tableData = info.data.data
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.logList()
      let optionsTask = await this.$getOptionTask()
      this.optionsTask = optionsTask.data.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
    this.logList()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
</style>
